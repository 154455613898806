type FlavorType = 'tickets';

const flavor: FlavorType = 'tickets';

export const environment = {
  production: true,
  flavor: flavor,
  sentryDsn: 'https://681fc57a274e47909618a81a52a573c3@o4504723517800448.ingest.sentry.io/4504723581435904',
  allowSentry: true,
  seatingLayoutHash: 'f5680a2b870480d029107510e634c7a4',
  i18nHash: 'd0a41fb7b43a6ad52f19eb551b0ed01b',
  defaultLanguage: 'hu',
  baseHref: '/',
  allowLogging: true,
  usePaymentEmulator: false,
  useApiEmulator: false,
  canChangeLanguage: true,
  allowPixelTracking: true,
  barionPixelId: 'BP-5H7FcFbrlR-35',
  shareUrl: 'https://in-time.hu/e',
  apiGatewayUrl: 'https://in-time-api-gateway-46gavk95.ew.gateway.dev',
  firebase: {
    apiKey: 'AIzaSyBGzlX19-MujJlnidbpOyrN1TcLgA2hHDY',
    authDomain: 'in-time-db42c.firebaseapp.com',
    databaseURL: 'https://in-time-db42c.firebaseio.com',
    projectId: 'in-time-db42c',
    storageBucket: 'in-time-db42c.appspot.com',
    messagingSenderId: '327503077961',
    appId: '1:327503077961:web:d0922f4555c2aff8835598',
    measurementId: 'G-S35WXSL6PE',
    storageApiUrl: 'https://storage.googleapis.com',
  }
};
